import * as React from "react";
import {
  Heading,
  Stack,
  VStack,
  WrapItem,
  Text,
  Container,
  Wrap,
  chakra,
} from "@chakra-ui/react";

import CheckSvg from "../../assets/svg/check.svg";

const Check = chakra(CheckSvg);

const otherFeatures = [
  {
    title: "Fast logging",
    description:
      "Log your food in record time! Copy foods and meals, log custom meals as units or separate ingredients, use Quick Add, Recent Logs, and other tools to make food logging lightning-fast.",
  },
  {
    title: "Simplicity and usability",
    description:
      "Most nutrition trackers are thick with features, but thin on usability. In contrast, each Basilium’s screen was designed to be simple to understand, display the most important information at a glance, and be comfortable to use.",
  },
  {
    title: "Nutrition goals",
    description:
      "Set up your daily macro and calorie goals and get a quick insight into exactly where things stand.",
  },
];

export const OtherFeaturesSection = () => {
  return (
    <Container as="section" maxW="7xl" centerContent>
      <Heading as="h2" mb="20">
        What's more
      </Heading>
      <Wrap justify="center" spacing="12">
        {otherFeatures.map(({ title, description }) => {
          return (
            <WrapItem>
              <Stack
                direction={{ base: "column", md: "row" }}
                align={{ base: "center", md: "flex-start" }}
                spacing="4"
              >
                <Check
                  width="6"
                  fill="brand.primary"
                  top="1.5"
                  pos="relative"
                />
                <VStack spacing={4} maxW="80" align={{ base: "center", md: "flex-start" }}>
                  <Heading
                    as="h3"
                    size="lg"
                    textAlign={{ base: "center", md: "left" }}
                  >
                    {title}
                  </Heading>
                  <Text fontSize='lg' lineHeight="8">{description}</Text>
                </VStack>
              </Stack>
            </WrapItem>
          );
        })}
      </Wrap>
    </Container>
  );
};
