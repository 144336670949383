import * as React from "react";
import { VStack } from "@chakra-ui/react";

import { MainSection } from "./MainSection";
import { FeaturesSection } from "./FeaturesSection";
import { OtherFeaturesSection } from "./OtherFeaturesSection";

export const HomePageContent = () => {
  return (
    <VStack spacing="40">
      <MainSection />
      <FeaturesSection />
      <OtherFeaturesSection />
    </VStack>
  );
};
