import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Heading,
  VStack,
  Flex,
  Text,
  Center,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";

import { FeaturesSectionQuery } from "../../../graphql-types";

const features = [
  {
    title: "Low-carb database",
    description:
      "It’s frustrating when a database contains millions of irrelevant, duplicate, or inaccurate foods. With Basilium, you get access to a minimalistic and verified food database that’s focused on low-carb diets. Less mess, less stress!",
    imageName: "low-carb-db",
    imageAltText: "In-app screen displaying low-carb database",
  },
  {
    title: "Custom foods and meals",
    description:
      "Doesn’t it suck to go through a grueling data entry process to create a simple food or meal? Basilium’s minimalistic approach enables “easy mode.” Craft custom foods and meals in just a few taps. Complete your entries in seconds.",
    imageName: "meal-creation",
    imageAltText: "In-app screen displaying meal creation",
  },
  {
    title: "Weight tracker",
    description:
      "When you are not aware of how your weight changes over time, it’s hard to stay the course. Basilium comes with a minimalistic and easy-to-use weight tracker to help you monitor your progress and increase the likelihood of hitting your weight goals.",
    imageName: "weight-tracker",
    imageAltText: "In-app screen displaying weight tracker",
  },
];

export const FeaturesSection = () => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const data = useStaticQuery<FeaturesSectionQuery>(graphql`
    query FeaturesSection {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          name
          childImageSharp {
            large: gatsbyImageData(
              height: 500
              outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            )
            small: gatsbyImageData(
              height: 400
              outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            )
          }
        }
      }
    }
  `);

  return (
    <Container as="section" maxW="5xl" centerContent>
      <Heading as="h2" mb="20">
        Features
      </Heading>
      <VStack spacing={{ base: "24", md: "16" }} w="100%">
        {features.map(
          ({ title, description, imageName, imageAltText }, index) => {
            const targetNode = data.allFile.nodes.find(
              (node) => node.name === imageName,
            );

            if (!targetNode) {
              throw new Error(`Image "${imageName}" was not found`);
            }
            const shouldReverseRow = index % 2 !== 0;

            const image = isSmallScreen
              ? targetNode.childImageSharp?.small
              : targetNode.childImageSharp?.large;
            return (
              <Flex
                w="100%"
                direction={{
                  base: "column",
                  md: shouldReverseRow ? "row-reverse" : "row",
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Center
                  width={{ base: "100%", md: "50%" }}
                  mb={{ base: "8", md: "0" }}
                >
                  <GatsbyImage alt={imageAltText} image={image} />
                </Center>
                <Center width={{ base: "100%", md: "50%" }}>
                  <VStack
                    spacing={4}
                    maxW={"96"}
                    align={{ base: "center", md: "flex-start" }}
                  >
                    <Heading as="h3" size="lg">
                      {title}
                    </Heading>
                    <Text fontSize="lg" lineHeight='8'>
                      {description}
                    </Text>
                  </VStack>
                </Center>
              </Flex>
            );
          },
        )}
      </VStack>
    </Container>
  );
};
