import * as React from "react";
import { Container, Box, Flex } from "@chakra-ui/react";

import { Header, Footer, Seo, SeoProps } from "../components";

type Props = {
  children: React.ReactNode;
} & SeoProps;

export const MainLayout = ({ children, ...seoProps }: Props) => {
  return (
    <>
      <Seo {...seoProps} />
      <Flex minH="100vh" direction="column" justifyContent="space-between">
        <Container maxW="7xl" p={{ base: "4", md: "8" }}>
          <Header />
          <Box as="main" my="28">
            {children}
          </Box>
        </Container>
        <Box bg="gray.50">
          <Container maxW="7xl" py="16" px={{ base: "4", md: "8" }}>
            <Footer />
          </Container>
        </Box>
      </Flex>
      {/* 
      The span is needed to navigate to the bottom of the footer
      if a url has #contacts in it
      */}
      <span id="contacts" />
    </>
  );
};
