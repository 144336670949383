import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Flex, Heading, VStack, Text, Grid, GridItem } from "@chakra-ui/react";

import { DownloadApp } from "../../components";

export const MainSection = () => {
  return (
    <Grid
      as="section"
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap="16"
      alignItems={"center"}
    >
      <GridItem>
        <Flex>
          <VStack
            alignItems={{ base: "center", md: "start" }}
            spacing="6"
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading as="h1" fontSize={{ base: "36px", lg: "40px" }}>
              <Text as="span" color="brand.primary">
                Basilium
              </Text>{" "}
              - minimalist low‑carb nutrition tracker
            </Heading>
            <Text fontSize="xl" pb="3">
              Basilium's minimalistic approach takes the hassle out of nutrition
              and weight tracking. Perfect for Keto, Paleo, and other low-carb
              diets.
            </Text>
            <DownloadApp
              spacing="4"
              badgeHeight={{ base: "42px", md: "48px" }}
            />
          </VStack>
        </Flex>
      </GridItem>

      <GridItem>
        <Flex justifyContent={{ base: "center", md: "flex-end" }}>
          <StaticImage
            height={650}
            alt="Food diary and food logging screens"
            src="../../assets/images/main.png"
          />
        </Flex>
      </GridItem>
    </Grid>
  );
};
