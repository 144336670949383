import * as React from "react";

import { MainLayout } from "../layouts/Main";
import { HomePageContent } from "../page-content/HomePage";

const IndexPage = () => {
  return (
    <MainLayout
      title="Basilium: minimalist low-carb & keto diet nutrition tracker"
      description="Basilium - minimalist nutrition and weight tracker with a low-carb focus. Perfect for Keto, Paleo, Primal, and other low-carb diets."
    >
      <HomePageContent />
    </MainLayout>
  );
};

export default IndexPage;
